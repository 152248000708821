<template>
	<section class="home_case">
		<div class="public_title">
			<span class="subtitle  wow fadeInUp">Company Case</span>
			<span class="tit  wow fadeInUp delays2">公司业务剪影</span>
		</div>

		<div class="case_details" v-if="show">
			<div class="ctx">
				<div class="title">{{ currentObj.text }}</div>
				<img :src="currentObj.src" alt="" />
			</div>
			<a @click="show = false" class="close"> x</a>
		</div>

		<div class="home_case-content layout">
			<swiper :options="caseSwiperOption">
				<swiper-slide v-for="item in caseList" :key="item.src">
					<div class="homeCase-item" @click="lookDetails(item)">
						<div class="imgbox">
							<img :src="item.src" :alt="item.text" :title="item.text">
						</div>
						<label>{{ item.type }}</label>
						<div class="text">
							<p :title="item.text">
								{{ item.text }}
							</p>
						</div>

						<div class="more">
							查看
						</div>
					</div>
				</swiper-slide>

				<div class="swiper-pagination" slot="pagination"></div>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>
		</div>


	</section>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default ({
	components: {
		swiper,
		swiperSlide,
	},
	data() {
		return {
			show: false,
			caseSwiperOption: {
				slidesPerView: 4,
				spaceBetween: 30,
				slidesPerGroup: 4,
				loop: true,
				autoplay: { delay: 4000 },
				loopFillGroupWithBlank: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},
				breakpoints: {
					//当宽度大于等于320
					320: {
						slidesPerView: 1,
						spaceBetween: 0
					},
					//当宽度大于等于480
					640: {
						slidesPerView: 1,
						spaceBetween: 20
					},
					//当宽度大于等于640
					840: {
						slidesPerView: 2,
						spaceBetween: 20
					}
				}
			},
			caseList: [
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/1.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/2.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/3.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/4.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/5.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/6.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/7.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/8.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲电催化除磷除锈",
					src: require('@/assets/images/dynamic/9.jpg'),
					text: "1月7日，陪同唐山滦洲领导考察：ECD（电催化除磷除锈）➕ED（钝化）➕水性冷涂锌/冷涂锌镁铝稀土合金涂料涂装一体业务"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/10.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/11.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/12.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/13.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/14.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "唐山滦洲ECD钢材无酸清洗➕冷涂锌镁铝项目",
					src: require('@/assets/images/dynamic/15.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/dynamic/16.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/dynamic/17.jpg'),
					text: "4月5日，我公司陪同 上海先锋医药控股有限公司 董事长 李新洲 与 河北滦洲经济开发区领导 就ECD钢材无酸清洗➕冷涂锌镁铝项目进行商务对接"
				},



				{
					type: "招商加盟",
					src: require('@/assets/images/case/homeCase_01.jpg'),
					text: "我公司与中铁十九局集团有限公司在浙江省丽水市莲都区洽谈招商引资项目"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/case/homeCase_02.jpg'),
					text: "我公司与浙江中政林业有限公司在河北省承德市隆化县洽谈碳达峰、碳中和，林业开发项目"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/case/homeCase_03.jpg'),
					text: "我公司与浙江中政林业有限公司在河北省承德市隆化县洽谈碳达峰、碳中和，林业开发项目"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/case/homeCase_04.jpg'),
					text: "河北工业大学净路检测者团队与河北凰聚科技有限公司战略合作签约仪式"
				},
				{
					type: "招商加盟",
					src: require('@/assets/images/case/homeCase_05.jpg'),
					text: "河北工业大学净路检测者团队与河北凰聚科技有限公司战略合作签约仪式"
				},
				{
					type: "碳达峰、碳中和、产业链",
					src: require('@/assets/images/case/carbonNeutral/01.jpg'),
					text: "沙雅县林业和草原局"
				},
				{
					type: "碳达峰、碳中和、产业链",
					src: require('@/assets/images/case/carbonNeutral/02.jpg'),
					text: "新和县"
				},
				{
					type: "医疗-血液净化系统",
					src: require('@/assets/images/case/medical/100.jpg'),
					text: "河南省鹿邑县"
				},
				{
					type: "医疗-血液净化系统",
					src: require('@/assets/images/case/medical/101.jpg'),
					text: "江西省永丰县"
				}
			],
			currentObj: {}
		}
	},

	methods: {
		lookDetails(item) {
			this.currentObj = item
			this.show = true
		}

	},
	mounted() {


	}
})
</script>
<style lang="less">
.case_details {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 100;

	.close {
		position: absolute;
		z-index: 101;
		right: 10px;
		top: 10px;
		color: #fff;
		font-size: 26px;
		cursor: pointer;
	}

	.ctx {
		width: 100%;
		height: 100%;
	}

	.title {
		padding: 40px 20px;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		color: #fff;
	}

	img {
		margin: 0 auto;
		max-width: 80%;
		max-height: 80%;
		display: block;
	}
}
</style>
