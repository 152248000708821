<template>
	<div class="home">

		<section class="home_about">
			<div class="public_title">
				<span class="subtitle wow fadeInUp">ABOUT US</span>
				<span class="tit wow fadeInUp delays2">关于我们</span>
			</div>
			<div class="layout">
				<div class="about_img wow fadeInLeft">
					<img src="@/assets/images/index/cb9731d0.jpg" />
				</div>
				<div class="about_info wow fadeInRight">
					<div class="title">
						<h1 class="title_color" data-content="关于凰聚">关于凰聚</h1>
					</div>
					<div class="text">
						<p>

							河北凰聚科技有限公司位于石家庄市新华区恒大中心1303室，是一家集聚多产业高新技术，科研成果的转化﹑转移的现代技术服务平台。招集全国高新技术企业，现已有碳达峰、碳中和、产业链、健康﹑环保﹑乡村振兴﹑智能制造﹑数字改造等产业的多家企业﹑研发院所入驻平台。智能化血液净化系统﹑区域健康信息平台﹑油泥低温烘干设备，以全球领先的硬核技术被政府与市场接受与推广。
						</p>
						<p>
							公司现有高素质技术人才及商务人员共36人，其中博士2名，本科﹑硕士及教授级高工以上占百分之九十五。
							公司以敬业的精神，专业的知识，集聚英才，有凰来仪，为政府搭桥梁﹑为企业找人脉﹑为产品找市场的服务宗旨，服务政府﹑服务市场；与科研院所联合开发，协作攻关服务于高新技术产业
						</p>
					</div>
					<ul class="aboutInfo_list">
						<li class="aboutInfo_item">
							<div class="item_img">
								<span class="iconfont icon-zijin"></span>
							</div>
							<div class="item_info">
								<h2 class="item_title">注册资金</h2>
								<div class="item_text">

									<span class="item_num"> <b id="year" data-value="5000" class="number">5000</b></span>万
								</div>
							</div>
						</li>
						<li class="aboutInfo_item">
							<div class="item_img">
								<span class="iconfont icon-shijian"></span>
							</div>
							<div class="item_info">
								<h2 class="item_title">成立时间</h2>
								<div class="item_text">
									<span class="item_num">2022-03-24</span>
								</div>
							</div>
						</li>
					</ul>
					<router-link to="/concate" class="web_more">
						<em>保持联系</em>
					</router-link>
				</div>
			</div>
		</section>

		<section class="banner" v-if="false">
			<swiper :options="swiperOption">
				<swiper-slide v-if="!isMobile">
					<router-link to="/products/temperature" class="banner">
						<img src="@/assets/images/index/banner_03.jpg" />
					</router-link>
				</swiper-slide>
				<swiper-slide v-if="!isMobile">
					<router-link to="/products/medical" class="banner">
						<img src="@/assets/images/index/banner_01.jpg" />
					</router-link>
				</swiper-slide>
				<swiper-slide v-if="!isMobile">
					<router-link to="/products/temperature" class="banner">
						<img src="@/assets/images/index/banner_02.jpg" />
					</router-link>
				</swiper-slide>


				<swiper-slide v-if="isMobile">
					<router-link to="/products/temperature" class="banner">
						<img src="@/assets/images/index/mobile_03.jpg" />
					</router-link>
				</swiper-slide>
				<swiper-slide v-if="isMobile">
					<router-link to="/products/medical" class="banner">
						<img src="@/assets/images/index/mobile_01.jpg" />
					</router-link>
				</swiper-slide>
				<swiper-slide v-if="isMobile">
					<img src="@/assets/images/index/mobile_02.jpg" />
				</swiper-slide>


				<div class="swiper-pagination" slot="pagination"></div>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>
		</section>

		<section class="home_products">
			<div class="public_title">
				<span class="subtitle wow fadeInUp">PRODUCTS CENTER</span>
				<span class="tit wow fadeInUp delays2">产品介绍</span>
			</div>

			<div class="products_content">
				<div class="products_item wow fadeInUp delays2">
					<span class="title">碳达峰、碳中和、产业链</span>
					<div class="imgBox">
						<img src="@/assets/images/index/products_01.jpg" alt="碳达峰、碳中和、产业链" />
					</div>
					<div class="intr">
						<div class="words ">
							浙江中政林业开发有限公司简介 及碳汇项目开发案例 浙江中政林业开发有限公司成立于 2011 年 6 月， 2019 年成为碳交易产业联盟理事单位， 2021
							年成为中国节能协会碳中和专业委员会常 务委员单位。 2019 年开发了湖北省随州市洪山森林经营 湖北洪山采伐转保护项目交易金额超3000万元。 2021年 9月 8
							日为新疆维吾尔自治区阿克苏 市新和县人民政府大楼本年度的碳中和。 目前正 在开发的项目有《湖北环潭VCS 禁伐项目》 《福建浦城 VCS 造林项目》《新疆沙雅 VCS 造林
							项目》《新疆沙雅CCER 项目》;正在进行的项目 有《福建建瓯市国有林场项目》《福建武夷山 市项目》《四川九寨沟县项目》《河南嵩县项 目》《河南潢川县项目》《新疆拜城县项目》等。
							类碳汇项目( HUBEI Hongshan IFM project)，项目编号 1935，该项目是目前国内单笔交易量最大的碳 汇项目，30年减排量680万吨.
						</div>
						<router-link class="btn" to="/products/carbonNeutral">查看详情</router-link>
					</div>
				</div>
				<div class="products_item wow fadeInUp delays3" v-if="false">
					<span class="title">环保-油烟分离</span>
					<div class="imgBox">
						<img src="@/assets/images/index/products_03.jpg" alt="环保-油烟分离" />
					</div>
					<div class="intr">
						<div class="words">
							根据国内外油泥处理处置技术的现状、油泥的特性、干油泥潜在的利用价值等状况，结合本公司在低温干燥行业多年的经验积累，研发防爆型油泥低温烘干油烟分离专用设备.
						</div>
						<router-link to="/products/environmentaFriendly" class="btn">查看详情</router-link>
					</div>
				</div>
				<div class="products_item wow fadeInUp delays4">
					<span class="title">深港文化交易</span>
					<div class="imgBox">
						<img src="@/assets/images/index/products_05.jpg" alt="深港文化交易" />
					</div>
					<div class="intr">
						<div class="words">
							<p>国尊商业是央企重组背景下独立开发的全品<br />
								类集合式商城:商城品类丰富多样,数码电器·手机数码·健康食品·美妆护肤·家具齐全<br />
								家居电器品牌:华为·小米·云米·美的-TCL-海信;<br />
								手机数码:华为·苹果·小米·三星·Oppo:健康食品:海晏堂-燕之屋·大连獐子岛海参山东黄金万足金9999:多款克数选择;大闸蟹精选:六款精选套盒任意选;港深赋能交易品种:<br />
								红山玉器:91.46元;中国船舶:1046元;<br />
								中国共产党成立90周年:369元:红军长征胜利80周年:162元;<br />
								国尊商城购买商品港深集团赋能空投线上藏品<br />
								高品质好品牌产品、硬通货、您真正所需的好品牌好产品好平台!
							</p>
						</div>
						<router-link to="/products/culture" class="btn">查看详情</router-link>
					</div>
				</div>

				<!-- <div class="products_item wow fadeInUp delays4">
					<span class="title">环保技-等离子空气消毒器</span>
					<div class="imgBox">
						<img src="@/assets/images/index/Sterilizer.jpg" alt="环保技-等离子空气消毒器" />
					</div>
					<div class="intr">
						<div class="words">
							<p>
								等离子体是物质在高温或特定激励(例如高压电离)下的一种物质存在聚集状态。是除通常物质三态:固态、液态、气态以外物质的第四种存在状态。
							</p>
							<p>
								等离子体是由大量正负带电粒子和中性粒子组成的、并表现出集体电场作用的、电荷整体呈准中性的气体云。由针状电正极和板式不锈钢阴极组成的等离子体反应器,加上高频高压脉冲电压,对空气进行高压电离,空气中的物质在针尖电极周围产生大范围的稳定放电等离子体。
							</p>
							<p>在外加高压电场的作用下,逸出电子和自然电子被加速,获得很高能量。—方面其内部的电场作用,对细菌细胞膜构成击穿和破坏;另一方面打开气体分子键,生成单原子分子、负氧离子、OH离子和自由氧原子、H20等自由基,具有活化和氧化能力。利用此原理在针状电极上加上高电压发生非热均匀性放电,产生稳定放电的等离子体进行灭杀细菌、病毒,分解有害有机物质。
							</p>
						</div>
						<router-link to="/products/sterilizer" class="btn">查看详情</router-link>
					</div>
				</div> -->

				<div class="products_item wow fadeInUp delays5">
					<span class="title">医疗信息平台</span>
					<div class="imgBox ">
						<img src="@/assets/images/index/products_02.jpg" alt="医疗信息平台" />
					</div>
					<div class="intr">
						<div class="words">
							通过四大平台的能力支撑，实现公共卫生、计划生育、医疗服务、医疗保障、药品供应保障、综合管理等六大业务应用，全面支撑便民惠
							民、业务协同和业务监管服务。

						</div>
						<router-link to="/products/medical" class="btn">查看详情</router-link>
					</div>
				</div>


				<div class="products_item wow fadeInUp delays6">
					<span class="title">数字乡村综合管理平台</span>
					<div class="imgBox">
						<img src="@/assets/images/index/products_04.jpg" al="数字乡村综合管理平台" />
					</div>
					<div class="intr">
						<div class="words">
							公司深耕于全民健康，智慧乡村建设，以及高新环保领域，在不断科技探索的同时致力于帮助企业和民众提供更好的生活方式.
						</div>
						<router-link to="/products/digitalVillage" class="btn">查看详情</router-link>
					</div>
				</div>
			</div>
		</section>

		<section class="home_join layout">
			<div class="public_title">
				<span class="subtitle  wow fadeInUp">JOIN US</span>
				<span class="tit  wow fadeInUp delays2">招商加盟</span>
			</div>
			<div class="list">
				<div class="item wow fadeInUp"><img src="@/assets/images/index/join_01.jpg" alt="碳达峰、碳中和、产业链" /></div>
				<div class="item words wow fadeInUp delays2">
					<div class="title">
						合作范围
					</div>
					<div class="subtitle">
						Scope of cooperation
					</div>
					<div class="Introduction">
						为政府搭桥梁，为企业找人脉，为产品找市场
					</div>
				</div>
				<div class="item wow fadeInUp delays3"><img src="@/assets/images/index/join_03.jpg" alt="碳达峰、碳中和、产业链" />
				</div>
				<div class="item words wow fadeInUp delays4">
					<div class="title">
						风险管控
					</div>
					<div class="subtitle">
						risk management
					</div>
					<div class="Introduction">
						公司现有专业的商务团队及大数据的支撑，对项目及产品做精准的市场分析并实际考察、研究，制作最合理的可行性报告
					</div>
				</div>
				<div class="item wow fadeInUp delays5" v-if="isMobile"><img src="@/assets/images/index/join_02.jpg"
						alt="碳达峰、碳中和、产业链" />
				</div>
				<div class="item words wow fadeInUp delays6">
					<div class="title">
						合作区域
					</div>
					<div class="subtitle">
						Cooperation area
					</div>
					<div class="Introduction">
						河北、浙江、海南，为核心，扩展至全国
					</div>
				</div>
				<div class="item wow fadeInUp delays7" v-if="!isMobile"><img src="@/assets/images/index/join_02.jpg"
						alt="碳达峰、碳中和、产业链" />
				</div>
				<div class="item wow fadeInUp delays8" v-if="isMobile"><img src="@/assets/images/index/join_04.jpg"
						alt="碳达峰、碳中和、产业链" />
				</div>
				<div class="item words wow fadeInUp delays9">
					<div class="title">
						加盟合作
					</div>
					<div class="subtitle">
						Join cooperation
					</div>
					<div class="Introduction">
						联系电话：15057195347（微信同号）
						联系人： 倪先生
					</div>
				</div>
				<div class="item wow fadeInUp delay-1s" v-if="!isMobile"><img src="@/assets/images/index/join_04.jpg"
						alt="碳达峰、碳中和、产业链" />
				</div>
			</div>
		</section>

		<!-- <section class="home_stamp">
			<div class="public_title">
				<span class="subtitle  wow fadeInUp">Huangju dynamic</span>
				<span class="tit  wow fadeInUp delays2">凰聚动态</span>
			</div>
			<div class="content">
				<div id="colee_left">
					<ul id="colee_left1">
						<li v-for="item in caseList" :key="item.src" class="homeCase-item">
							<div class="imgbox">
								<img :src="item.src">
								<label>{{ item.type }}</label>
							</div>
							<div class="text">
								<p>
									{{ item.text }}
								</p>
							</div>
						</li>
					</ul>
					<ul id="colee_left2" valign="top"></ul>
				</div>
			</div>
		</section> -->



		<CaseLayout></CaseLayout>

	</div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import CaseLayout from "./components/case"
export default ({
	components: {
		swiper,
		swiperSlide,
		CaseLayout
	},
	data() {
		return {
			currentCase: 1,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 30,
				slidesPerGroup: 1,
				loop: true,
				autoplay: { delay: 8000 },
				// loopFillGroupWithBlank: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				}
			},

			isMobile: false,

		}
	},

	methods: {
		countup: function () {
			var options = {
				useEasing: true,
				useGrouping: true,
				separator: ',',
				decimal: '.',
			};
			document.querySelectorAll(".number").forEach(element => {
				let dom = $(element);
				let id = dom.attr("id");
				let value = element.innerHTML;
				var demo = new CountUp(id, 0, value, 0, 2.5, options);
				demo.start();
			});
		},


		getMobile() {
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
				this.isMobile = true
				return
			}
			if (document.documentElement.clientWidth < 1200) {
				this.isMobile = true
			} else {
				this.isMobile = false
			}
		},

		marqueeCase() {
			//使用div时，请保证colee_left2与colee_left1是在同一行上. 
			var speed = 30//速度数值越大速度越慢 
			var colee_left2 = document.getElementById("colee_left2");
			var colee_left1 = document.getElementById("colee_left1");
			var colee_left = document.getElementById("colee_left");
			colee_left2.innerHTML = colee_left1.innerHTML
			function Marquee3() {
				if (colee_left2.offsetWidth - colee_left.scrollLeft <= 0)//offsetWidth 是对象的可见宽度 
					colee_left.scrollLeft -= colee_left1.offsetWidth//scrollWidth 是对象的实际内容的宽，不包边线宽度 
				else {
					colee_left.scrollLeft++
				}
			}
			var MyMar3 = setInterval(Marquee3, speed)
			colee_left.onmouseover = function () {
				clearInterval(MyMar3)
			}
			colee_left.onmouseout = function () { MyMar3 = setInterval(Marquee3, speed) }
		}
	},
	mounted() {
		// this.marqueeCase()
		// window.onresize = (e) => {
		// 	this.getMobile()
		// }
		this.getMobile()
		this.$nextTick(() => {
			this.countup()
		})
	}
})
</script>
